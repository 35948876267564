<template>
  <v-container style="height: calc(100vh - 60px); overflow-y: auto" fluid>
    <v-row>
      <v-col cols="3">
        <v-select
          :items="examinations"
          v-model="query.examinationId"
          item-text="name"
          item-value="id"
          label="Kíp thi"
          dense
          @change="getSubjects"
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-select
          :items="subjects"
          item-text="fullName"
          v-model="query.subjectId"
          item-value="id"
          label="Học phần"
          dense
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="3">
        <v-btn
          @click="
            selectedExam = 1;
            getExams();
          "
          color="primary"
          :disabled="!query.subjectId || !query.examinationId"
          >Xem</v-btn
        >
        <v-btn
          @click="deleteExams"
          color="primary"
          :disabled="exams.length === 0"
          >Xóa</v-btn
        >
      </v-col>
      <!-- 
      <v-col v-if="originalExam" cols="6">
        <v-card class="mt-0 pt-1" style="width:100%">
          <v-select
            :items="originalExams"
            v-model="selectedOriginalExam"
            label="Đề gốc"
            class="ml-4"
            hide-details
            item-text="name"
            style="width:50%"
            item-value="id"
          ></v-select>
          <v-card-text style="height: calc(100vh - 177px);overflow-y: auto">
            <div v-for="question in originalExam.questions" :key="question.id">
              <h4 class="mb-2">
                Câu {{ question.order }} - Level {{ question.level }}
              </h4>
              <img :src="question.content" alt="" />
              <div v-for="choice in question.choices" :key="choice.id">
                <p
                  :style="{
                    color: choice.isCorrect ? 'green' : 'unset',
                    fontSize: '14px'
                  }"
                  class="my-2"
                >
                  Phương án {{ choice.order }}:
                  <span v-if="choice.type === 'text'">{{
                    choice.content
                  }}</span>
                </p>
                <img :src="choice.image" v-if="choice.image" alt="" />
              </div>
              <v-divider class="my-2"></v-divider>
            </div>
          </v-card-text>
        </v-card>
      </v-col> -->
      <v-col v-if="exam" cols="6">
        <v-card class="mt-0 pt-1" style="width:100%">
          <v-row>
            <v-col cols="6">
              <v-select
                :items="exams"
                hide-details
                v-model="selectedExam"
                class="ml-4 mr-0"
                label="Mã đề"
                @change="getExams"
              ></v-select
            ></v-col>
            <v-col cols="6">
              <v-btn class="mt-5" small @click="$refs.html2Pdf.generatePdf()"
                >PDF</v-btn
              >
            </v-col>
          </v-row>

          <v-card-text style="height: calc(100vh - 250px);overflow-y: auto">
            <div v-for="question in exam.questions" :key="question.id">
              <h4 class="mb-2">
                Câu {{ question.order }} - Level
                {{ question.originalQuestion.level }}
              </h4>
              <img :src="question.originalQuestion.content" alt="" />
              <div v-for="choice in question.choices" :key="choice.id">
                <p
                  :style="{
                    color: choice.originalChoice.isCorrect ? 'green' : 'unset',
                    fontSize: '14px'
                  }"
                  class="my-2"
                >
                  Phương án {{ choice.order }}:
                  <span v-if="choice.originalChoice.type === 'text'">{{
                    choice.originalChoice.content
                  }}</span>
                </p>
                <img
                  :src="choice.originalChoice.image"
                  v-if="choice.originalChoice.image"
                  alt=""
                />
              </div>
              <v-divider class="my-2"></v-divider>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <Exam ref="html2Pdf" :exam="exam" />
  </v-container>
</template>
<script>
import {
  getSubjects,
  index,
  getExaminations,
  getExams,
  deleteExams
} from "@/api/original-exam";
import Exam from "@/components/pdf/Exam";
export default {
  components: { Exam },
  data() {
    return {
      query: {
        subjectId: "",
        examinationId: ""
      },
      subjects: [],
      examinations: [],
      originalExams: [],
      exams: [],
      selectedOriginalExam: null,
      selectedExam: 1,
      exam: null
    };
  },

  methods: {
    async getExams() {
      try {
        this.$loader(true);
        const { data } = await getExams({
          subjectId: this.query.subjectId,
          examinationId: this.query.examinationId,
          code: this.selectedExam
        });
        this.exams = Array.from(Array(data.count).keys()).map(item => item + 1);
        if (this.exams.length === 0)
          return this.$snackbar("Chưa có đề thi nào", "error");
        this.exam = data.exam;
      } catch (error) {
        console.log(error);
      } finally {
        this.$loader(false);
      }
    },
    async getSubjects(val) {
      const examination = this.examinations.find(item => item.id === val);
      this.subjects = examination.subjects;
    },
    async getExaminations() {
      const { data } = await getExaminations({ owner: true });
      this.examinations = data;
    },
    async getOriginalExams() {
      this.$loader(true);
      const examination = this.examinations.find(
        i => i.id === this.query.examinationId
      );
      const { data } = await index({
        subjectId: this.query.subjectId,
        termId: examination.termId,
        type: examination.type,
        withDetail: true
      });

      this.originalExams = data;
      if (data.length > 0) this.selectedOriginalExam = data[0].id;
      this.getExams();
      this.$loader(false);
    },
    async deleteExams() {
      try {
        const confirm = await this.$confirm(
          "Bạn có muốn xóa tất cả đề thi của kíp này?",
          {
            color: "info",
            icon: "mdi-information",
            title: "Xóa đề thi",
            buttonTrueText: "Đồng ý",
            buttonFalseText: "Hủy"
          }
        );
        if (confirm) {
          this.$loader(true);
          await deleteExams({
            subjectId: this.query.subjectId,
            examinationId: this.query.examinationId
          });
          this.exam = null;
          this.$snackbar("Xóa dữ liệu thành công", "success");
          this.$emit("deleted");
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$loader(false);
      }
    }
  },
  created() {
    this.getExaminations();
  }
};
</script>